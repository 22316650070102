<template>
  <div>
    <b-table
      class="lvj-table"
      :items="conversions"
      :fields="fields"
      bordered
      striped
      hover
    >
      <template #head(actions)>
        <b-button
          variant="success"
          size="sm"
          block
          class="mr-2"
          @click="openCreateConversionModal"
        >
          NOVO
        </b-button>
      </template>
      <template #cell(actions)="row">
        <b-button
          variant="primary"
          size="sm"
          class="mr-2"
          @click="openEditConversionModal(row.item)"
        >
          <b-icon icon="pencil" />
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          class="mr-2"
          @click="openRemoveConversionModal(row.item.id)"
        >
          <b-icon icon="x-circle" />
        </b-button>
      </template>
    </b-table>
    <b-modal
      id="modal-center"
      title="Novo Conversão"
      v-model="showFormModal"
      size="xl"
      centered
      hide-footer
      hide-header
    >
      <b-form @submit="onSubmitAttribute" class="mb-3">
        <b-form-group label="Marca:" label-for="input-1">
          <b-form-input
            v-model="form.marca"
            placeholder="Digite a marca"
            required
          />
        </b-form-group>

        <b-form-group label="Código:" label-for="input-2">
          <b-form-input
            v-model="form.codigo_marca"
            placeholder="Digite o código da marca"
            required
          />
        </b-form-group>

        <b-form-group label="Produto de conversão:" label-for="input-2">
          <b-input-group>
            <b-form-input
              v-model="productConversion"
              placeholder="Adicione um produto"
              required
              disabled
            />

            <b-input-group-append>
              <b-button variant="danger" @click="removeSelection">
                <b-icon icon="x" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-button type="submit" variant="primary">SALVAR</b-button>
      </b-form>
      <!-- Search -->
      <search-input
        v-model="search"
        :is-loading-search="isLoadingSearch"
        placeholder="Pesquise um produto"
        full
        @enter="onSearch"
      />

      <b-table
        :items="products"
        :fields="fieldsProduct"
        :busy="isLoadingSearch"
        :style="{ borderTop: 'hidden' }"
        empty-text="Não ha produtos para mostrar"
        striped
        hover
      >
        <!-- Loading -->
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Buscando...</strong>
          </div>
        </template>

        <!-- Button -->
        <template #cell(actions)="row">
          <b-button
            variant="success"
            size="sm"
            class="mr-2"
            @click="setSelection(row.item)"
            v-if="!row.item.inserted"
            :pressed="row.item.id === form.conversao_id"
          >
            <b-icon icon="check" />
          </b-button>
        </template>
      </b-table>
    </b-modal>
    <custom-modal
      v-model="showRemoveModal"
      title="Tem certeza?"
      message="Deseja apagar Conversão"
      @confirm="removeConversion"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import http from "../../plugins/http";
import CustomModal from "../shared/CustomModal.vue";
import SearchInput from "../shared/SearchInput.vue";

export default {
  name: "ConversionForm",
  components: { CustomModal, SearchInput },
  props: {
    conversions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showFormModal: false,
      showRemoveModal: false,
      idToRemove: 0,
      search: "",
      page: 2,
      form: {
        id: 0,
        produto_id: Number(this.$route.params.id),
        conversao_id: null,
        marca: "",
        codigo_marca: "",
        conversao: null,
      },
      fields: [
        {
          key: "marca",
          label: "Marca",
          sortable: true,
        },
        {
          key: "codigo_marca",
          label: "Código",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ],
      fieldsProduct: [
        {
          key: "codigo_produto",
          label: "Código",
          sortable: true,
        },
        {
          key: "descricao_completa",
          label: "Descrição",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },

  computed: {
    ...mapState("product", ["products", "isLoadingSearch", "isLoading"]),

    productConversion() {
      const { form } = this;
      return form.conversao
        ? `${form.conversao.codigo_produto}: ${form.conversao.descricao_completa}`
        : "";
    },
  },
  methods: {
    ...mapActions("product", ["searchProducts", "getMoreSearch"]),
    ...mapMutations(["setToastedSuccess", "setToastedError"]),
    ...mapMutations("product", ["resetProductList"]),

    resetForm() {
      this.form = {
        id: 0,
        produto_id: Number(this.$route.params.id),
        conversao_id: null,
        marca: "",
        codigo_marca: "",
        conversao: null,
      };
    },
    openCreateConversionModal() {
      this.resetProductList();
      this.resetForm();
      this.showFormModal = true;
    },
    openEditConversionModal(values) {
      this.resetProductList();
      this.form = { ...values };
      this.showFormModal = true;
    },
    openRemoveConversionModal(id) {
      this.showRemoveModal = true;
      this.idToRemove = id;
    },
    async onSearch() {
      const { search } = this;
      this.page = 2;
      if (search) {
        await this.searchProducts(search);
      }
    },
    setSelection(product) {
      this.form.conversao_id = product.id;
      this.form.conversao = {
        id: product.id,
        codigo_produto: product.codigo_produto,
        descricao_completa: product.descricao_completa,
      };
    },
    removeSelection() {
      this.form.conversao_id = null;
      this.form.conversao = null;
    },
    async create() {
      try {
        const { data } = await http.post("conversion", this.form);
        this.$emit("create-conversion", data);
        this.setToastedSuccess({
          message: "Conversão foi criada com sucesso",
        });
      } catch (err) {
        this.setToastedError({
          message: err.response.data.error,
        });
      }
    },
    async update() {
      try {
        const url = `conversion/${this.form.id}`;
        const { data } = await http.put(url, this.form);
        this.$emit("update-conversion", data);
        this.setToastedSuccess({
          message: "Conversão foi atualizada com sucesso",
        });
      } catch (err) {
        this.setToastedError({
          message: err.response.data.error,
        });
      }
    },
    async remove(id) {
      try {
        await http.delete(`conversion/${id}`);
        this.$emit("remove-conversion", id);
        this.setToastedSuccess({
          message: "Conversão foi removida com sucesso",
        });
      } catch (err) {
        this.setToastedError({
          message: err.response.data.error,
        });
      }
    },
    async onSubmitAttribute(event) {
      event.preventDefault();

      if (this.form.id) {
        this.update();
        this.showFormModal = false;
      } else {
        this.create();
      }

      this.resetForm();
    },
    removeConversion() {
      const { idToRemove } = this;

      if (idToRemove) {
        this.remove(idToRemove);
      }

      this.showRemoveModal = false;
    },
  },
};
</script>

<style scss scoped>
.lvj-table::v-deep tr th:last-child {
  width: 8%;
}
</style>
