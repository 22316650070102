<template>
  <div>
    <b-table :items="formattedItems" :fields="fields" bordered striped hover />
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  name: "PriceHistoryTable",
  data() {
    return {
      dialog: false,
      search: "",
      page: 2,
      fields: [
        {
          key: "preco_anterior",
          sortable: true,
        },
        {
          key: "preco_novo",
          sortable: true,
        },
        {
          key: "data_modificado",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    formattedItems() {
      const optionDate = {
        year: "numeric",
        month: "long" || "short" || "numeric",
        weekday: "long" || "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };

      const optionPrice = {
        style: "currency",
        currency: "BRL",
      };

      return this.items.map((e) => ({
        ...e,
        preco_novo: new Intl.NumberFormat("pt-BR", optionPrice).format(
          e.preco_novo
        ),
        preco_anterior: new Intl.NumberFormat("pt-BR", optionPrice).format(
          e.preco_anterior
        ),
        data_modificado: new Date(e.data_modificado).toLocaleDateString(
          "pt-BR",
          optionDate
        ),
      }));
    },
  },
};
</script>

<style></style>
