<template>
  <div>
    <b-table
      class="lvj-table"
      :items="verifyAttributes"
      :fields="fields"
      bordered
      striped
      hover
    >
      <template #head(actions)>
        <b-button
          variant="success"
          size="sm"
          block
          class="mr-2"
          @click="openCreateAttributeModal"
        >
          NOVO
        </b-button>
      </template>
      <template #cell(actions)="row">
        <b-button
          variant="primary"
          size="sm"
          class="mr-2"
          @click="openEditAttributeModal(row.item)"
        >
          <b-icon icon="pencil" />
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          class="mr-2"
          @click="openRemoveAttributeModal(row.item.id)"
        >
          <b-icon icon="x-circle" />
        </b-button>
      </template>
    </b-table>
    <b-modal
      id="modal-center"
      title="Novo Atributo"
      centered
      hide-footer
      v-model="showFormModal"
    >
      <b-form @submit="onSubmitAttribute">
        <b-form-group id="input-group-1" label="Atributo:" label-for="input-1">
          <b-form-input
            id="input-1"
            v-model="form.atributo"
            placeholder="Digite o atributo"
            required
          />
        </b-form-group>

        <b-form-group id="input-group-2" label="Valor:" label-for="input-2">
          <b-form-input
            id="input-2"
            v-model="form.valor"
            placeholder="Digite o valor"
            required
          />
        </b-form-group>
        <b-button type="submit" variant="primary">SALVAR</b-button>
      </b-form>
    </b-modal>
    <custom-modal
      v-model="showRemoveModal"
      title="Tem certeza?"
      message="Deseja apagar atributo"
      @confirm="removeAtributo"
    />
  </div>
</template>

<script>
import CustomModal from "../shared/CustomModal.vue";
export default {
  components: { CustomModal },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showFormModal: false,
      showRemoveModal: false,
      idToRemove: 0,
      form: {
        id: 0,
        atributo: "",
        valor: "",
      },
      fields: [
        {
          key: "atributo",
          sortable: true,
        },
        {
          key: "valor",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },
  computed: {
    verifyAttributes() {
      const testFn = (testItem, item) =>
        testItem.atributo.toLowerCase() == item.atributo.toLowerCase();

      return this.items.map((e, i) => ({
        ...e,
        _rowVariant:
          this.items.findIndex((testItem) => testFn(testItem, e)) !== i &&
          "danger",
      }));
    },
  },
  methods: {
    openCreateAttributeModal() {
      this.showFormModal = true;
      this.form = {
        atributo: "",
        valor: "",
        id: 0,
      };
    },
    openEditAttributeModal(values) {
      this.showFormModal = true;
      this.form = values;
    },
    openRemoveAttributeModal(id) {
      this.showRemoveModal = true;
      this.idToRemove = id;
    },
    async onSubmitAttribute(event) {
      event.preventDefault();
      const { form } = this;

      if (form.id) {
        this.$emit("update-attribute", form);
      } else {
        this.$emit("create-attribute", form);
      }
      this.form = {
        atributo: "",
        valor: "",
        id: 0,
      };
    },
    removeAtributo() {
      const { idToRemove } = this;

      if (idToRemove) {
        this.$emit("remove-attribute", idToRemove);
      }

      this.showRemoveModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.lvj-table::v-deep tr th:last-child {
  width: 8%;
}
</style>
