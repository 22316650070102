<template>
  <div>
    <b-table
      :items="vehicleFormatted"
      :fields="fields"
      @row-clicked="(item) => clickRow(item)"
      bordered
      striped
      hover
    >
      <template #head(actions)>
        <b-button
          variant="success"
          size="sm"
          block
          class="mr-2"
          @click="openCreateModal"
        >
          NOVO
        </b-button>
      </template>

      <template #cell(posicao)="row">
        <b-form-input
          v-if="row.item.editPosition"
          v-model="row.item.posicao"
          @keypress.enter="saveOnInput(row.item)"
        />
        <span v-else>{{ row.item.posicao }}</span>
      </template>

      <template #cell(ano_inicial)="row">
        <b-form-input
          v-if="row.item.editPosition"
          v-model="row.item.ano_inicial"
          @keypress.enter="saveOnInput(row.item)"
        />
        <span v-else>{{ row.item.ano_inicial }}</span>
      </template>

      <template #cell(ano_final)="row">
        <b-form-input
          v-if="row.item.editPosition"
          v-model="row.item.ano_final"
          @keypress.enter="saveOnInput(row.item)"
        />
        <span v-else>{{ row.item.ano_final }}</span>
      </template>

      <template #cell(actions)="row">
        <b-button
          variant="primary"
          size="sm"
          class="mr-2"
          @click="openEditModal(row.item)"
        >
          <b-icon icon="pencil" />
        </b-button>

        <b-button
          variant="danger"
          size="sm"
          class="mr-2"
          @click="openRemoveModal(row.item.id)"
        >
          <b-icon icon="x-circle" />
        </b-button>
      </template>
    </b-table>

    <b-modal
      id="modal-center"
      :title="form.id ? 'Editar Veiculo' : 'Novo Veiculo'"
      centered
      hide-footer
      v-model="showCreateModal"
    >
      <b-form @submit="onSubmit">
        <form-select
          v-model="form.modeloId"
          :options="comboboxFormatted"
          label="Modelo:"
          required
        />

        <form-select
          v-model="form.eixo"
          :options="[
            {
              id: 'D',
              text: 'Dianteiro',
            },
            {
              id: 'T',
              text: 'Traseiro',
            },
            {
              id: 'A',
              text: 'Dianteiro e Traseiro',
            },
            {
              id: 'X',
              text: 'Auxiliar',
            },
            {
              id: 'K',
              text: 'Trucado',
            },
            {
              id: 'C',
              text: 'Traçado',
            },
            {
              id: 'E1',
              text: '1º Eixo',
            },
            {
              id: 'E2',
              text: '2º Eixo',
            },
            {
              id: 'E3',
              text: '3º Eixo',
            },
          ]"
          label="Eixo:"
        />

        <form-input
          v-model="form.posicao"
          label="Posição:"
          placeholder="Digite a posição no veiculo"
        />

        <form-input
          v-model="form.anoInicial"
          label="Ano Inicial:"
          placeholder="Digite ano inicial do veiculo"
        />

        <form-input
          v-model="form.anoFinal"
          label="Ano Final:"
          placeholder="Digite ano final do veiculo"
        />

        <b-button type="submit" variant="primary">SALVAR</b-button>
      </b-form>
    </b-modal>

    <custom-modal
      v-model="showRemoveModal"
      title="Tem certeza?"
      message="Deseja apagar a associação com o veiculo"
      @confirm="onDeleteBrand"
    />
  </div>
</template>

<script>
import http from "../../plugins/http";
import FormInput from "../../components/form/FormInput.vue";
import FormSelect from "../../components/form/FormSelect.vue";
import CustomModal from "../../components/shared/CustomModal.vue";
import { mapMutations } from "vuex";

export default {
  name: "VehicleTable",
  components: { FormInput, FormSelect, CustomModal },
  data() {
    return {
      showCreateModal: false,
      showRemoveModal: false,
      vehicleId: 0,
      form: {
        modeloId: 0,
        eixo: "",
        anoInicial: "",
        anoFinal: "",
        posicao: "",
      },
      modelCombobox: [],
      listVehicle: [],
      fields: [
        {
          key: "modelo.marca.descricao",
          label: "Marca",
          sortable: true,
        },
        {
          key: "modelo.descricao",
          label: "Modelo",
          sortable: true,
        },
        {
          key: "eixoText",
          label: "Eixo",
          sortable: true,
        },
        {
          key: "posicao",
          label: "Posição",
          sortable: true,
          thStyle: {
            width: "195px !important",
          },
        },
        {
          key: "ano_inicial",
          sortable: true,
          thStyle: {
            width: "195px !important",
          },
        },
        {
          key: "ano_final",
          sortable: true,
          thStyle: {
            width: "195px !important",
          },
        },
        {
          key: "actions",
          label: "",
          thStyle: {
            width: "195px !important",
          },
        },
      ],
    };
  },
  computed: {
    comboboxFormatted() {
      return this.modelCombobox.map((model) => ({
        id: model.id,
        text: `${model.descricao} - ${model.marca.descricao}`,
      }));
    },
    vehicleFormatted() {
      return this.listVehicle.map((model) => {
        let eixoText = "";

        if (model.eixo === "A") {
          eixoText = "Ambos";
        }

        if (model.eixo === "D") {
          eixoText = "Dianteiro";
        }

        if (model.eixo === "T") {
          eixoText = "Traseiro";
        }

        if (model.eixo === "X") {
          eixoText = "Auxiliar";
        }

        if (model.eixo === "K") {
          eixoText = "Trucado";
        }

        if (model.eixo === "C") {
          eixoText = "Traçado";
        }

        if (model.eixo === "E1") {
          eixoText = "1º Eixo";
        }

        if (model.eixo === "E2") {
          eixoText = "2º Eixo";
        }

        if (model.eixo === "E3") {
          eixoText = "3º Eixo";
        }

        return {
          ...model,
          eixoText,
          editPosition: false,
        };
      });
    },
  },
  methods: {
    ...mapMutations(["setToastedSuccess", "setToastedError"]),

    clickRow(item) {
      item.editPosition = !item.editPosition;
    },
    saveOnInput(item) {
      this.form = {
        ...item,
        anoInicial: item.ano_inicial,
        anoFinal: item.ano_final,
      };
      this.onEditVehicle();
    },
    openCreateModal() {
      this.form = {
        modeloId: 0,
        eixo: "",
        anoInicial: "",
        anoFinal: "",
        posicao: "",
      };
      this.showCreateModal = true;
    },
    async onCreateVehicle() {
      const id = this.$route.params.id;

      try {
        const { data } = await http.post(`product/${id}/vehicle`, {
          modeloId: this.form.modeloId,
          eixo: this.form.eixo,
          anoInicial: this.form.anoInicial
            ? Number(this.form.anoInicial)
            : undefined,
          anoFinal: this.form.anoFinal ? Number(this.form.anoFinal) : undefined,
          posicao: this.form.posicao ? Number(this.form.posicao) : undefined,
        });
        this.listVehicle = [...this.listVehicle, data];
        this.setToastedSuccess({ message: "Associação com veiculo criada" });
      } catch (err) {
        this.setToastedError({
          message: "Erro ao associar veiculo, tente novamente mais tarde",
        });
      }
    },
    openEditModal(item) {
      this.form = {
        id: item.id,
        modeloId: item.modelo_id,
        eixo: item.eixo,
        anoInicial: item.ano_inicial,
        anoFinal: item.ano_final,
        posicao: item.posicao,
      };
      this.showCreateModal = true;
    },
    async onEditVehicle() {
      const id = this.$route.params.id;

      try {
        const { data } = await http.put(
          `product/${id}/vehicle/${this.form.id}`,
          {
            modeloId: this.form.modeloId,
            eixo: this.form.eixo,
            anoInicial: this.form.anoInicial
              ? Number(this.form.anoInicial)
              : null,
            anoFinal: this.form.anoFinal ? Number(this.form.anoFinal) : null,
            posicao: this.form.posicao ? Number(this.form.posicao) : null,
          }
        );
        const index = this.listVehicle.findIndex(
          (model) => model.id === this.form.id
        );
        const vehicleCopy = [...this.listVehicle];
        vehicleCopy.splice(index, 1, data);

        this.listVehicle = vehicleCopy;
        this.setToastedSuccess({
          message: "Associação com veiculo atualizada",
        });
      } catch (err) {
        this.setToastedError({
          message: "Erro ao atualizar associação, tente novamente mais tarde",
        });
      }
    },
    openRemoveModal(id) {
      this.vehicleId = id;
      this.showRemoveModal = true;
    },
    async onDeleteBrand() {
      const id = this.$route.params.id;

      try {
        await http.delete(`product/${id}/vehicle/${this.vehicleId}`);
        this.listVehicle = this.listVehicle.filter(
          (vehicle) => vehicle.id !== this.vehicleId
        );
        this.setToastedSuccess({ message: "Associação com veiculo removida" });
      } catch (err) {
        this.setToastedError({
          message: "Erro ao remover associação, tente novamente mais tarde",
        });
      }
      this.showRemoveModal = false;
    },
    async onSubmit(event) {
      event.preventDefault();

      if (this.form.id) {
        await this.onEditVehicle();
      } else {
        await this.onCreateVehicle();
      }

      this.showCreateModal = false;
    },
  },
  async created() {
    const id = this.$route.params.id;

    const [{ data: listVehicle }, { data: modelCombobox }] = await Promise.all([
      http.get(`product/${id}/vehicle`),
      http.get("brand/model/combobox"),
    ]);

    this.listVehicle = listVehicle;
    this.modelCombobox = modelCombobox;
  },
};
</script>

<style></style>
