<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between align-items-center mb-2">
          <div class="d-flex flex-column justify-content-center">
            <h3>Detalhes do produto</h3>
            <span> {{ pageHeader.code }} - {{ pageHeader.desc }} </span>
          </div>
          <search-input
            v-model="searchProduct"
            :is-loading-search="isLoadingSearch"
            @enter="search"
          />
        </div>

        <b-tabs fill>
          <b-tab title="GERAL" class="mt-3">
            <b-row>
              <b-col>
                <b-form @submit="createProduct">
                  <b-row>
                    <b-col>
                      <form-input
                        v-model="form.codigo_produto"
                        label="Código:"
                        icon="three-dots"
                        copyable
                        disabled
                      />
                    </b-col>
                    <b-col>
                      <form-input
                        v-model="form.codigo_barra"
                        label="Código de Barra:"
                        icon="upc"
                        disabled
                      />
                    </b-col>
                    <b-col>
                      <form-input
                        :value="
                          new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(form.preco)
                        "
                        label="Preço de venda:"
                        icon="cash"
                        disabled
                      />
                    </b-col>
                    <b-col>
                      <form-input
                        :value="`${form.ipi}%`"
                        label="IPI:"
                        icon="calculator"
                        disabled
                      />
                    </b-col>
                    <b-col>
                      <form-input
                        :value="`${form.estoque} ${form.unidade}`"
                        label="Estoque atual:"
                        icon="columns"
                        disabled
                      />
                    </b-col>
                  </b-row>

                  <form-input
                    v-model="form.descricao_completa"
                    label="Descrição completa:"
                    placeholder="Digite a descrição"
                    icon="card-heading"
                    copyable
                  />

                  <b-row>
                    <b-col>
                      <form-input
                        :value="form.referencia"
                        label="Referência:"
                        placeholder="Digite a referência"
                        icon="card-list"
                        disabled
                      />
                    </b-col>
                    <b-col>
                      <form-input
                        v-model="form.descricao"
                        label="Descrição SGI:"
                        icon="clipboard-minus"
                        disabled
                        copyable
                      />
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="3">
                      <form-input
                        v-model="form.embalagem"
                        label="Quantidade na embalagem:"
                        placeholder="Digite a quantidade"
                        icon="box-seam"
                        :disabled="true"
                      />
                    </b-col>
                    <b-col cols="3">
                      <form-input
                        v-model="form.embalagem_unidade"
                        label="Unidade na embalagem:"
                        placeholder="Digite a unidade"
                        icon="collection"
                      />
                    </b-col>
                    <b-col>
                      <form-select
                        v-model="form.caixa_id"
                        label="Caixa:"
                        :options="formattedBoxes"
                        value-field="value"
                        icon="box"
                      />
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <form-input
                        v-model="form.altura"
                        label="Altura (CM):"
                        type="number"
                        placeholder="Valor em centímetros"
                        icon="rulers"
                      />
                    </b-col>
                    <b-col>
                      <form-input
                        v-model="form.largura"
                        label="Largura (CM):"
                        type="number"
                        placeholder="Valor em centímetros"
                        icon="rulers"
                      />
                    </b-col>
                    <b-col>
                      <form-input
                        v-model="form.comprimento"
                        label="Comprimento (CM):"
                        type="number"
                        placeholder="Valor em centímetros"
                        icon="rulers"
                      />
                    </b-col>
                    <b-col>
                      <form-input
                        label="Peso:"
                        v-model="form.peso"
                        placeholder="Peso não informado"
                        icon="stack"
                        disabled
                      />
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <form-select
                        v-model="form.catalogo_id"
                        label="Catalogo:"
                        :options="catalogs"
                        text-field="descricao"
                        value-field="id"
                        icon="book-half"
                      />
                    </b-col>
                    <b-col>
                      <form-select
                        v-model="form.grupo_id"
                        label="Grupo:"
                        :options="groups"
                        text-field="grupo"
                        value-field="id"
                        icon="journal-bookmark"
                      />
                    </b-col>
                  </b-row>

                  <div class="d-flex justify-content-end">
                    <b-button type="submit" variant="primary">
                      GRAVAR ALTERAÇÕES
                    </b-button>
                  </div>
                </b-form>
              </b-col>
              <b-col cols="3">
                <b-col>
                  <h5>Foto do Produto</h5>

                  <b-row>
                    <image-fallback
                      :key="imageFallbackKey"
                      :image="imageUrl"
                      alt="Click para visualizar a imagem."
                    />
                  </b-row>
                  <b-row align-h="end" class="mr-2">
                    <b-form-file
                      v-model="imageToUpload"
                      accept="image/jpeg, image/png, image/webp"
                      ref="image-upload"
                      plain
                      hidden
                      @input="uploadImage"
                    />
                    <b-button
                      variant="primary"
                      size="sm"
                      class="mr-2"
                      title="Enviar ou alterar a foto do produto."
                      @click="$refs['image-upload'].$el.click()"
                    >
                      <b-icon icon="cloud-upload" />
                    </b-button>
                    <b-button
                      variant="danger"
                      size="sm"
                      class="mr-2"
                      title="Remover a foto do produto."
                      @click="openRemoveImageDialog"
                    >
                      <b-icon icon="x-circle" />
                    </b-button>
                  </b-row>
                </b-col>
                <b-col class="mt-5">
                  <h5>Configurações de Exibição</h5>

                  <b-row>
                    <b-form-checkbox
                      id="checkbox-1"
                      name="checkbox-1"
                      v-model="form.exibir_ipi"
                      inline
                    >
                      Exibir IPI
                    </b-form-checkbox>
                  </b-row>
                  <b-row>
                    <b-form-checkbox
                      id="checkbox-2"
                      name="checkbox-2"
                      v-model="form.exibir"
                      inline
                    >
                      Exibir Produto
                    </b-form-checkbox>
                  </b-row>
                  <b-row>
                    <b-form-checkbox
                      id="checkbox-3"
                      name="checkbox-3"
                      v-model="form.novidade"
                      inline
                    >
                      Novidade
                    </b-form-checkbox>
                  </b-row>
                </b-col>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="ATRIBUTOS" class="mt-3">
            <attributes-table
              :items="form.atributos"
              @create-attribute="createAtributo"
              @update-attribute="updateAtributo"
              @remove-attribute="removeAtributo"
            />
          </b-tab>
          <b-tab title="CONVERSÕES" class="mt-3">
            <conversion-form
              :conversions="form.conversao"
              @create-conversion="createConversion"
              @update-conversion="updateConversion"
              @remove-conversion="removeConversion"
            />
          </b-tab>
          <b-tab title="RELACIONADOS" class="mt-3">
            <relations-table
              :items="relations"
              @new-relation="createRelation"
              @remove-relation="removeRelation"
            />
          </b-tab>
          <b-tab title="MARCAÇÕES" class="mt-3">
            <div>
              <form-textarea
                v-model="form.tags"
                label="Marcações (Tags):"
                placeholder="Digite as marcações:"
                icon="tags"
                :rows="10"
                max-rows="6"
              />
            </div>
            <div class="d-flex justify-content-end">
              <b-button variant="primary" @click="createProduct">
                GRAVAR ALTERAÇÕES
              </b-button>
            </div>
          </b-tab>
          <b-tab title="VEÍCULOS">
            <vehicle-table />
          </b-tab>
          <b-tab title="DICIONÁRIO" class="mt-3">
            <div>
              <form-textarea
                label="Palavras Chave"
                :rows="10"
                :value="form.palavra_chave"
                icon="ui-checks"
                disabled
              />
              <form-textarea
                label="Erros Ortográficos"
                :rows="10"
                :value="form.erros_ortograficos"
                icon="type"
                disabled
              />
            </div>
          </b-tab>
          <b-tab title="HISTÓRICO DE PREÇO" class="mt-3">
            <price-history-table :items="priceHistory" />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <custom-modal
      v-model="removeImageDialog"
      title="Tem certeza?"
      message="Deseja remover essa imagem."
      @confirm="removeImage"
    />
  </b-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import FormInput from "../../components/form/FormInput.vue";
import FormSelect from "../../components/form/FormSelect.vue";
import FormTextarea from "../../components/form/FormTextarea.vue";
import AttributesTable from "../../components/products/AttributesTable.vue";
import ConversionForm from "../../components/products/ConversionForm.vue";
import PriceHistoryTable from "../../components/products/PriceHistoryTable.vue";
import RelationsTable from "../../components/products/RelationsTable.vue";
import VehicleTable from "../../components/products/VehicleTable.vue";
import CustomModal from "../../components/shared/CustomModal.vue";
import ImageFallback from "../../components/shared/ImageFallback.vue";
import SearchInput from "../../components/shared/SearchInput.vue";
import http from "../../plugins/http";

export default {
  name: "productDetails",
  components: {
    RelationsTable,
    PriceHistoryTable,
    AttributesTable,
    ImageFallback,
    CustomModal,
    ConversionForm,
    FormInput,
    FormTextarea,
    FormSelect,
    SearchInput,
    VehicleTable,
  },
  data() {
    return {
      imageFallbackKey: 0,
      pageHeader: {
        code: this.$store.state.product.product.codigo_produto,
        desc: this.$store.state.product.product.descricao_completa,
      },
      form: { ...this.$store.state.product.product },
      relations: [],
      priceHistory: [],
      imageToUpload: null,
      imageUrl: "",
      removeImageDialog: false,
      searchProduct: "",
    };
  },
  computed: {
    ...mapState("product", ["catalogs", "groups", "isLoadingSearch"]),
    ...mapGetters("box", ["formattedBoxes"]),
  },
  methods: {
    ...mapActions("product", [
      "updateProduct",
      "getOneProduct",
      "getProductCatalogs",
      "getProductGroup",
      "searchProducts",
    ]),
    ...mapActions("box", ["getAllBoxes"]),
    ...mapMutations([
      "setToastedSuccess",
      "setToastedError",
      "setToasted",
      "setOverlay",
    ]),

    async search() {
      if (this.searchProduct) {
        await this.searchProducts(this.searchProduct);
        this.$router.push("/produtos");
      }
    },
    async createProduct(event) {
      event.preventDefault();
      await this.updateProduct(this.form);
    },
    async createAtributo(form) {
      try {
        const id = this.$route.params.id;
        const url = `product/${id}/attributes`;
        const { data } = await http.post(url, form);
        this.form.atributos.push(data);
        this.$bvToast.toast(`Atributo criado com sucesso`, {
          title: "Sucesso",
          autoHideDelay: 5000,
          variant: "success",
          solid: true,
        });
      } catch (err) {
        this.$bvToast.toast(`Não foi possível criar o atributo`, {
          title: "Erro",
          autoHideDelay: 5000,
          variant: "danger",
          solid: true,
        });
      }
    },
    async updateAtributo(form) {
      const url = `product/updateProductAttributes/${form.id}`;
      const { data } = await http.put(url, form);
      const index = this.form.atributos.findIndex((e) => e.id === data.id);
      this.form.atributos.splice(index, 1, data);
    },
    async removeAtributo(id) {
      try {
        await http.delete(`product/attributes/${id}`);
        this.form.atributos = this.form.atributos.filter((e) => e.id !== id);
      } catch (err) {
        this.$bvToast.toast(`${err.response.data.error}`, {
          title: "Erro",
          autoHideDelay: 5000,
          variant: "danger",
          solid: true,
        });
      }
    },
    async getPriceHistory() {
      const { form } = this;
      const url = `product/priceHistory/${form.codigo_produto}`;
      const { data } = await http.get(url);
      this.priceHistory = data;
    },
    async getRelations() {
      const { form } = this;
      const url = `product/${form.id}/relation`;
      const { data } = await http.get(url);
      this.relations = data;
    },
    async createRelation(relationId) {
      try {
        const { form } = this;
        const url = `product/${form.id}/relation/${relationId}`;
        await http.post(url);
        this.getRelations();
      } catch (err) {
        this.setToasted({
          title: "ERRO",
          message: err.response.data.error,
          variant: "danger",
          visible: true,
        });
      }
    },
    async removeRelation(id) {
      const { form } = this;
      await http.delete(`product/${form.id}/relation/${id}`);
      this.relations = this.relations.filter((e) => e.id !== id);
    },
    async uploadImage() {
      try {
        const { imageToUpload, form } = this;
        const formData = new FormData();
        formData.append("image", imageToUpload);
        formData.append("productCode", form.codigo_produto);

        const { data } = await http.post("/image/product", formData);

        this.imageUrl = data.imgUrl;

        this.setToastedSuccess({
          message: "A imagem foi enviada com sucesso",
        });
      } catch (err) {
        this.setToastedError({
          message: "Não foi possível enviar a imagem",
        });
      }
      this.imageFallbackKey++;
    },
    async removeImage() {
      try {
        const { form } = this;
        await http.delete(`/image/product/${form.codigo_produto}`);
        this.imageUrl = "";
        this.setToastedSuccess({
          message: "A imagem foi removida com sucesso",
        });
      } catch (err) {
        this.setToastedError({
          message: "Não foi possível remover a imagem",
        });
      }
      this.imageFallbackKey++;
      this.removeImageDialog = false;
    },
    openRemoveImageDialog() {
      this.removeImageDialog = true;
    },
    createConversion(data) {
      this.form.conversao.push(data);
    },
    updateConversion(data) {
      const index = this.form.conversao.findIndex((e) => e.id === data.id);
      this.form.conversao.splice(index, 1, data);
    },
    removeConversion(id) {
      this.form.conversao = this.form.conversao.filter((e) => e.id !== id);
    },
    openUrl(url) {
      window.open(url, "_blank");
    },
  },
  async created() {
    this.setOverlay(true);
    const id = this.$route.params.id;

    if (id && !this.form.id) {
      await this.getOneProduct(id).catch(() => this.$router.push("/produtos"));
      this.form = { ...this.$store.state.product.product };

      this.pageHeader = {
        code: this.$store.state.product.product.codigo_produto,
        desc: this.$store.state.product.product.descricao_completa,
      };
    }

    this.imageUrl = `https://lvj.ind.br/app/webp/produto/original/${this.form.codigo_produto}.webp`;
    this.imageFallbackKey++;

    await Promise.all([
      this.getProductCatalogs(),
      this.getProductGroup(),
      this.getRelations(),
      this.getPriceHistory(),
      this.getAllBoxes(),
    ]).finally(() => this.setOverlay(false));
  },
};
</script>
