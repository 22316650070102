<template>
  <div class="mb-3">
    <b-img
      v-if="!imageError"
      v-bind="mainProps"
      :src="image"
      :alt="alt"
      :title="alt"
      fluid
      class="pointer"
      @click="open(image)"
      @error="onError"
    />
    <b-img
      v-else
      v-bind="mainProps"
      src="https://lvj.ind.br/img/not-found-product-original.png"
      :alt="alt"
      fluid
    />
    <b-modal v-model="openModal" title="Imagem" size="lg" hide-footer>
      <b-img v-bind="mainProps" :src="image" fluid class="mb-2" />
      <form-input :value="image" icon="card-image" copyable disabled />
    </b-modal>
  </div>
</template>

<script>
import FormInput from "../form/FormInput.vue";

export default {
  components: { FormInput },
  props: {
    image: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: false,
    },
  },
  name: "ImageFallback",
  data() {
    return {
      openModal: false,
      imageError: false,
      mainProps: {
        width: 800,
        height: 800,
      },
    };
  },
  methods: {
    open() {
      this.openModal = !this.openModal;
    },
    onError() {
      this.imageError = true;
      this.$emit("erro", "");
    },
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
</style>
