<template>
  <div>
    <b-table
      class="lvj-table"
      :items="items"
      :fields="fields"
      bordered
      striped
      hover
    >
      <template #head(actions)>
        <b-button
          variant="success"
          size="sm"
          block
          class="mr-2"
          @click="openRelationDialog"
        >
          NOVO
        </b-button>
      </template>
      <template #cell(actions)="row">
        <b-button
          variant="danger"
          size="sm"
          class="mr-2"
          @click="openRemoveDialog(row.item)"
        >
          <b-icon icon="x-circle" />
        </b-button>
      </template>
    </b-table>
    <b-modal
      id="modal-relation"
      size="xl"
      v-model="newRelationDialog"
      centered
      hide-footer
      hide-header
    >
      <b-table
        :items="relationList"
        :fields="fields"
        :busy="isLoadingSearch"
        :style="{ borderTop: 'hidden' }"
        empty-text="Não ha produtos para mostrar"
        striped
        hover
      >
        <!-- Search -->
        <template #thead-top>
          <b-tr>
            <b-th colspan="3">
              <search-input
                v-model="search"
                :is-loading-search="isLoadingSearch"
                placeholder="Pesquise um produto"
                initial-focus
                full
                @enter="onSearch"
              />
            </b-th>
          </b-tr>
        </template>

        <!-- Loading -->
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Buscando...</strong>
          </div>
        </template>

        <!-- Button -->
        <template #cell(actions)="row">
          <b-button
            variant="success"
            size="sm"
            class="mr-2"
            @click="newRelation(row.item.id)"
            v-if="!row.item.inserido"
          >
            <b-icon icon="check" />
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            class="mr-2"
            @click="openRemoveDialog(row.item)"
            v-else
          >
            <b-icon icon="x-circle" />
          </b-button>
        </template>
      </b-table>
      <b-button @click="getMore" block variant="primary">
        <b-icon icon="arrow-clockwise" animation="spin" v-if="isLoading" />
        CARREGAR MAIS
      </b-button>
    </b-modal>
    <custom-modal
      v-model="removeDialog"
      title="Tem certeza?"
      :message="message"
      @confirm="removeRelation"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import CustomModal from "../shared/CustomModal.vue";
import SearchInput from "../shared/SearchInput.vue";
// const SearchInput = () => import("../shared/SearchInput.vue");

export default {
  components: { CustomModal, SearchInput },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  name: "RelationsTable",
  data() {
    return {
      newRelationDialog: false,
      removeDialog: false,
      idToRemove: 0,
      search: "",
      page: 2,
      message: "",
      fields: [
        {
          key: "codigo_produto",
          sortable: true,
        },
        {
          key: "descricao_completa",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },
  computed: {
    ...mapState("product", ["products", "isLoadingSearch", "isLoading"]),

    disableButton() {
      return this.relationList.length > 1;
    },
    relationList() {
      return this.products.map((e) => ({
        ...e,
        inserido: this.items.find((item) => item.id === e.id),
      }));
    },
  },
  methods: {
    ...mapActions("product", ["searchProducts", "getMoreSearch"]),
    ...mapMutations("product", ["resetProductList"]),

    openRemoveDialog({ id, codigo_produto }) {
      this.message = `Deseja remover a relação com o produto: ${codigo_produto}?`;
      this.idToRemove = id;
      this.removeDialog = true;
    },
    openRelationDialog() {
      this.resetProductList();
      this.search = "";
      this.newRelationDialog = true;
    },
    async onSearch() {
      const { search } = this;
      this.page = 2;
      if (search) {
        await this.searchProducts(search);
      }
    },
    async getMore() {
      const { page, search } = this;

      await this.getMoreSearch({ page, search });

      this.page = page + 1;
    },
    newRelation(id) {
      this.$emit("new-relation", id);
    },
    removeRelation() {
      const { idToRemove } = this;
      if (idToRemove) {
        this.$emit("remove-relation", idToRemove);
      }
      this.removeDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.lvj-table::v-deep tr th:last-child {
  width: 8%;
}
</style>
